.wallet-bar {
    position: absolute;
    top: 15px;
    padding: 0;
    z-index: 10000;
    margin: 10px auto;
    min-height: 50px;
}
.wallet-bar a {
    display: inline-block;
}
.wallet-bar hr {
    margin: 0 5px !important;
}
.wallet-bar span {
    padding: 0 5px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wallet-bar .hide {
    display: none;
}

