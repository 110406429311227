.center, .modal {
    justify-content: center;
    text-align: center;
}
.center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.modal-open-btn {
    margin-top: 10px !important;
}

.modal .vertical-action-group {
    margin: 20px auto 0;
}

.d-flex {
    display: flex;
}
