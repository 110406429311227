.vote.section {
    align-items: normal;
}

.vote .vote-option:nth-child(even) {
    background-color: #e8e8e8;
}

.vote .search-container {
    display: flex;
    background-color: #e8e8e8 !important;
    margin-bottom: 25px;
}
.vote .search-container > div {
    width: 100%;
}
