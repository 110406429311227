@media screen and (min-width: 800px) {
    /* Desktop */
    .paper {
        margin: 0;
        margin-right: 1em;
    }
}
@media screen and (max-width: 799px) {
    /* Mobile */
    .paper {
        margin: 0;
    }
}

.paper, div.paper, .paper > span, .paper h1 {
    color: white;
}
.paper {
    height: 380px;
    width: 300px;
    padding-right: 0;
}
div.paper {
    background-color: #4a4e60;
}
.paper > span {
    text-align: center;
    justify-content: center;
    width: 260px;
    display: inline-block;
    margin: 20px;
    height: 50px;
}
.paper h1 {
    font-size: 3em;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    cursor: default;
}
.w-100 {
    width: 100%;
}
.italic {
    font-style: italic;
}
