@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

@media screen and (min-width: 800px) {
    .mobile {
        visibility: hidden;
        display: none !important;
    }
    .desktop {
        visibility: visible;
        display: unset;
    }
    .section-header h1 {
        font-size: 4em;
    }
}
@media screen and (max-width: 799px) {
    .mobile {
        visibility: visible;
        display: unset;
    }

    .desktop {
        visibility: hidden;
        display: none !important;
    }

    .section-header h1 {
        font-size: 2em;
    }
}
@media screen and (min-height: 599px) {
    .scroll-down-absolute {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 170px;
    }
}
@media screen and (max-height: 599px) and (min-height: 410px) {
    .scroll-down-absolute {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 100px;
    }
}
@media screen and (max-height: 409px) {
    .scroll-down-absolute {
        display: none;
    }
}
body {
    background-color: #4a4e60;
}
.home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: calc(100vh);
    z-index: 1000;
}
.primary {
    background-color: #4a4e60;
    color: white;
}
.greeting {
    margin: -63px 20px 0 20px;
}
.hide {
    display: none;
}
.greeting-text {
    margin: 15px;
}
.links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    max-width: 60px;
    margin: 20px auto 0 auto;
}
.links a,
.links img {
    width: 50px;
    height: 50px;
}
.scroll-down-absolute-element {
    display: inline-block;
}
.scroll-down {
    margin-top: 50px;
    display: block;
    justify-content: center;
    text-align: center;
}
.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh);
    width: 100%;
    background-size: cover;
    object-fit: cover;
}
.section {
    min-height: calc(100vh);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
.section:nth-child(odd) {
    background-color: #6c7789;
}
.section-header {
    color: white;
    flex-wrap: wrap;
}
.section-header h1 {
    letter-spacing: 0.1em;
    /* See media queries for font-size */
    font-weight: 100;
    font-family: "Merriweather", serif;
    text-transform: uppercase;
    text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.3), -2px 4px 2px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}
.section-header h2 {
    font-size: 1.3em;
    margin: 0 auto .5em;
    line-height: 1.5;
    letter-spacing: 0.125pt;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    font-family: 'Droid Serif',"Helvetica Neue",Helvetica,Arial,sans-serif;
    flex-wrap: wrap;

}
.center {
    justify-content: center;
    text-align: center;
}
.stack {
    color: white;
    flex-wrap: wrap;
    max-width: 100%;
}

.contact-icon {
    padding: 20px;
    transition: 1000ms linear;
}

.contact-icon:hover {
    border-radius: 50%;
    background-color: #4a4e60;
    transition: 1000ms linear;
}

.subtitle {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.subtitle > div {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5px;
    padding: 0;
}
.subtitle .quote {
    font-size: 1.2em;
    font-weight: bold;
    justify-content: center;
    align-content: center;
}
.subtitle .author {
    font-size: 1em;
    font-style: italic;
    justify-content: right;
    margin-top: 0;
    padding-top: 0;
    margin-left: 25%;
}

.intro-paragraph {
    max-width: 1000px;
    margin: 20px;
}
.bigger-font {
    font-size: 1.1em;
}

.centering-container {
    display: flex;
    justify-items: center;
}
.paper .centering-container {
    min-height: 60px;
}
.paper .centering-container .center {
    margin: auto;
}

button.MuiButton-containedPrimary, a.MuiButton-containedPrimary {
    background-color: #6c7789;
}
button.MuiButton-containedPrimary:hover, a.MuiButton-containedPrimary:hover {
    background-color: #6c7999;
}

button.btn-bright, a.btn-bright {
    background-color: #a024ff;
}
button.btn-bright:hover, a.btn-bright:hover {
    background-color: #7900d6;
}


.medium-link {
    margin-top: 50px;
}
