@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (min-width: 800px) {
    .mobile {
        visibility: hidden;
        display: none !important;
    }
    .desktop {
        visibility: visible;
        display: unset;
    }
    .section-header h1 {
        font-size: 4em;
    }
}
@media screen and (max-width: 799px) {
    .mobile {
        visibility: visible;
        display: unset;
    }

    .desktop {
        visibility: hidden;
        display: none !important;
    }

    .section-header h1 {
        font-size: 2em;
    }
}
@media screen and (min-height: 599px) {
    .scroll-down-absolute {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 170px;
    }
}
@media screen and (max-height: 599px) and (min-height: 410px) {
    .scroll-down-absolute {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 100px;
    }
}
@media screen and (max-height: 409px) {
    .scroll-down-absolute {
        display: none;
    }
}
body {
    background-color: #4a4e60;
}
.home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: calc(100vh);
    z-index: 1000;
}
.primary {
    background-color: #4a4e60;
    color: white;
}
.greeting {
    margin: -63px 20px 0 20px;
}
.hide {
    display: none;
}
.greeting-text {
    margin: 15px;
}
.links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    max-width: 60px;
    margin: 20px auto 0 auto;
}
.links a,
.links img {
    width: 50px;
    height: 50px;
}
.scroll-down-absolute-element {
    display: inline-block;
}
.scroll-down {
    margin-top: 50px;
    display: block;
    justify-content: center;
    text-align: center;
}
.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh);
    width: 100%;
    background-size: cover;
    object-fit: cover;
}
.section {
    min-height: calc(100vh);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
.section:nth-child(odd) {
    background-color: #6c7789;
}
.section-header {
    color: white;
    flex-wrap: wrap;
}
.section-header h1 {
    letter-spacing: 0.1em;
    /* See media queries for font-size */
    font-weight: 100;
    font-family: "Merriweather", serif;
    text-transform: uppercase;
    text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.3), -2px 4px 2px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}
.section-header h2 {
    font-size: 1.3em;
    margin: 0 auto .5em;
    line-height: 1.5;
    letter-spacing: 0.125pt;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    font-family: 'Droid Serif',"Helvetica Neue",Helvetica,Arial,sans-serif;
    flex-wrap: wrap;

}
.center {
    justify-content: center;
    text-align: center;
}
.stack {
    color: white;
    flex-wrap: wrap;
    max-width: 100%;
}

.contact-icon {
    padding: 20px;
    transition: 1000ms linear;
}

.contact-icon:hover {
    border-radius: 50%;
    background-color: #4a4e60;
    transition: 1000ms linear;
}

.subtitle {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.subtitle > div {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5px;
    padding: 0;
}
.subtitle .quote {
    font-size: 1.2em;
    font-weight: bold;
    justify-content: center;
    align-content: center;
}
.subtitle .author {
    font-size: 1em;
    font-style: italic;
    justify-content: right;
    margin-top: 0;
    padding-top: 0;
    margin-left: 25%;
}

.intro-paragraph {
    max-width: 1000px;
    margin: 20px;
}
.bigger-font {
    font-size: 1.1em;
}

.centering-container {
    display: flex;
    justify-items: center;
}
.paper .centering-container {
    min-height: 60px;
}
.paper .centering-container .center {
    margin: auto;
}

button.MuiButton-containedPrimary, a.MuiButton-containedPrimary {
    background-color: #6c7789;
}
button.MuiButton-containedPrimary:hover, a.MuiButton-containedPrimary:hover {
    background-color: #6c7999;
}

button.btn-bright, a.btn-bright {
    background-color: #a024ff;
}
button.btn-bright:hover, a.btn-bright:hover {
    background-color: #7900d6;
}


.medium-link {
    margin-top: 50px;
}

@media screen and (min-width: 800px) {
    /* Desktop */
    .paper {
        margin: 0;
        margin-right: 1em;
    }
}
@media screen and (max-width: 799px) {
    /* Mobile */
    .paper {
        margin: 0;
    }
}

.paper, div.paper, .paper > span, .paper h1 {
    color: white;
}
.paper {
    height: 380px;
    width: 300px;
    padding-right: 0;
}
div.paper {
    background-color: #4a4e60;
}
.paper > span {
    text-align: center;
    justify-content: center;
    width: 260px;
    display: inline-block;
    margin: 20px;
    height: 50px;
}
.paper h1 {
    font-size: 3em;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    cursor: default;
}
.w-100 {
    width: 100%;
}
.italic {
    font-style: italic;
}

.wallet-bar {
    position: absolute;
    top: 15px;
    padding: 0;
    z-index: 10000;
    margin: 10px auto;
    min-height: 50px;
}
.wallet-bar a {
    display: inline-block;
}
.wallet-bar hr {
    margin: 0 5px !important;
}
.wallet-bar span {
    padding: 0 5px !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wallet-bar .hide {
    display: none;
}


.center, .modal {
    justify-content: center;
    text-align: center;
}
.center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.modal-open-btn {
    margin-top: 10px !important;
}

.modal .vertical-action-group {
    margin: 20px auto 0;
}

.d-flex {
    display: flex;
}

.vote.section {
    align-items: normal;
}

.vote .vote-option:nth-child(even) {
    background-color: #e8e8e8;
}

.vote .search-container {
    display: flex;
    background-color: #e8e8e8 !important;
    margin-bottom: 25px;
}
.vote .search-container > div {
    width: 100%;
}

.vote.section {
    align-items: normal;
}

.vote .vote-option:nth-child(even) {
    background-color: #e8e8e8;
}

.vote .search-container {
    display: flex;
    background-color: #e8e8e8 !important;
    margin-bottom: 25px;
}
.vote .search-container > div {
    width: 100%;
}

